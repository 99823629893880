import {Link} from '~components/common/link'
import JesterHat from '@fool/jester-ui/JesterHat'

type LogoProps = {
  textColor?: string
  logoText?: string
}

export default function EpicLogo({textColor, logoText}: LogoProps) {
  return (
    <Link href="/">
      <div className="flex justify-center items-center gap-x-1">
        <div className="w-[51px]">
          <JesterHat />
        </div>
        <span
          className={`text-[22px] font-bold pr-1 ${
            textColor ? textColor : 'text-content-0 '
          }`}
        >
          {logoText}
        </span>
      </div>
    </Link>
  )
}
