'use client'

import '~styles/globals.css'
import {satoshi} from 'src/font.ts'
import Button from '@fool/jester-ui/Button'
import Heading from '@fool/jester-ui/Heading'
import EpicLogo from '~components/logo/epic'
import Cookies from 'js-cookie'
import {usePathname, useSearchParams} from 'next/navigation'
import {
  FOOL_AUTH_COOKIE_DOMAIN,
  FOOL_AUTH_COOKIE_PATH,
  getLastauthCookieKey,
} from '~auth/fool-auth-utils'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ProviderDown = ({children}: {children: React.ReactNode}) => {
  const pathname = usePathname()
  const searchParams = useSearchParams()

  // If you are trying to sign in and you already have a lastauth
  // cookie, that means you are being silently authenticated into
  // this app.
  // If there is an outage, we should delete the lastauth cookie
  // to prevent enabling silent auths wherever possible as to not
  // redirect the user to Auth0 if it is down.
  Cookies.remove(getLastauthCookieKey(), {
    path: FOOL_AUTH_COOKIE_PATH,
    domain: FOOL_AUTH_COOKIE_DOMAIN,
  })

  const retryUrl = decodeURIComponent(`${pathname}?${searchParams}`)
  const prefixedRetryUrl = `/premium/site${retryUrl}`

  return (
    <html
      lang="en"
      className="h-full bg-primary-4 absolute block left-0 right-0"
    >
      <body
        className={`${satoshi.variable} ${satoshi.className} h-full mx-4 overscroll-none overflow-x-hidden`}
      >
        <div
          className="
                sticky top-0 z-40
                border-b md:border-none border-content-8 bg-primary-0 md:shadow-sm
                px-16px md:px-6 lg:px-8 md:pt-4 py-4
              "
        >
          <header className="bg-white w-full flex gap-x-2 xxl:gap-x-12px items-center justify-between">
            <div className="flex w-full">
              <div className="hidden md:flex shrink-0">
                <EpicLogo logoText="" textColor="text-black" />
              </div>
            </div>
          </header>
        </div>
        <div className="bg-primary-4 flex items-center justify-center flex-wrap">
          <span className="relative w-auto max-w-full top-[120px] right-0 lg:top-[96px] md:-right-[14px]"></span>
          <h1 className="block w-full text-[136px] lg:text-[220px] leading-[220px] font-regular text-content-70 text-center">
            Oh no!
          </h1>
          <Heading
            as="h1"
            looksLike="h1"
            className="text-center w-full text-black my-6"
          >
            We&apos;re really sorry to be holding you up today!
          </Heading>
          <p className="text-black text-body-regular text-center">
            Customers are currently experiencing issues logging into our
            services.
            <br />
            Please wait a few minutes and try again.
          </p>
          <div className="w-full flex flex-nowrap items-center justify-center mt-8">
            <a href={prefixedRetryUrl}>
              <Button variant="primary">Try Again</Button>
            </a>
          </div>
        </div>
      </body>
    </html>
  )
}

export default ProviderDown
