'use client'

import {useTransition, useCallback, useEffect, useRef, useMemo} from 'react'
import NextLink from 'next/link'
import {useRouter} from 'next/navigation'
import classNames from 'classnames'

const LAYOUT_PREFIXES = ['flex', 'grid', 'gap-']

/***
 * Custom next/link wrapper that provides a consistent loading UX for all links.
 * Based on the Vercel example from https://github.com/vercel/next.js/discussions/41934#discussioncomment-8996669.
 *
 * Next.js removed the router.events API in app-router, which would have
 * allowed for a more elegant solution.
 */
export function Link({
  href,
  children,
  replace,
  truncate,
  forceContainerClasses,
  setIsPending: parentSetIsPending,
  onClick: parentOnClick,
  onNavigationComplete: parentOnNavigationComplete,
  ...rest
}: Parameters<typeof NextLink>[0] & {
  truncate?: boolean
  forceContainerClasses?: boolean
  setIsPending?: (isPending: boolean) => void
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void
  onNavigationComplete?: () => void
}) {
  const router = useRouter()
  const [isPending, startTransition] = useTransition()
  const isNavigating = useRef(false)

  useEffect(() => {
    if (parentSetIsPending) {
      parentSetIsPending(isPending)
    }

    if (isNavigating.current && !isPending && parentOnNavigationComplete) {
      parentOnNavigationComplete()
      isNavigating.current = false
    }
  }, [isPending, parentSetIsPending, parentOnNavigationComplete])

  const handleNavigation = useCallback(() => {
    isNavigating.current = true
    const url = href.toString()
    if (replace) {
      router.replace(url)
    } else {
      router.push(url)
    }
  }, [href, router, replace])

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      startTransition(() => {
        if (parentOnClick) {
          parentOnClick(e)
        }

        if (!e.defaultPrevented) {
          e.preventDefault()
          handleNavigation()
        }
      })
    },
    [handleNavigation, parentOnClick],
  )

  const {className, ...restProps} = rest

  const truncateContainerClass = useMemo(
    () => (truncate ? 'min-w-0 flex-1' : ''),
    [truncate],
  )

  const truncateInnerClass = useMemo(
    () => (truncate ? 'min-w-0 truncate block' : ''),
    [truncate],
  )

  const {containerClasses, innerClasses} = useMemo(() => {
    if (!className) {
      return {
        containerClasses: truncateContainerClass,
        innerClasses: truncateInnerClass,
      }
    }

    const hasLayoutClass = className
      .split(' ')
      .some((cls) => LAYOUT_PREFIXES.some((prefix) => cls.startsWith(prefix)))

    // Generally, apply all classes to inner span when layout classes are
    // present on the link component itself.
    const shouldApplyToInnerSpan = !forceContainerClasses && hasLayoutClass

    return {
      containerClasses: classNames(
        !shouldApplyToInnerSpan ? className : '',
        truncateContainerClass,
      ),
      innerClasses: classNames(
        shouldApplyToInnerSpan ? className : '',
        truncateInnerClass,
      ),
    }
  }, [className, truncateContainerClass, truncateInnerClass])

  const innerSpanClasses = useMemo(
    () => classNames(innerClasses, isPending && 'cursor-wait opacity-70'),
    [innerClasses, isPending],
  )

  return (
    <NextLink
      href={href}
      onClick={handleClick}
      {...restProps}
      className={containerClasses}
    >
      {/* Pending styles do not work when applied directly to next/link */}
      <span className={innerSpanClasses}>{children}</span>
    </NextLink>
  )
}
